/* eslint-disable no-undef */
import './share-image.scss';
import { useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth'

const imageErrorSrc = 'https://images.getdunmag.com/bevy/add-photo-default.jpg'
const { REACT_APP_STAGE: STAGE } = process.env
const API_URL = `https://api.${STAGE || 'dev'}.bevymagazine.com`

const Modal = () => (
  <div>
    <p>The modal is open</p>
  </div>
)
const toggleModal = (e, showModel, setShowModel) => {
  e.preventDefault()
  setShowModel(!showModel)
}

const loadWasm = async (setPhoton, setLoadedWasm) => {
  try {
    const photon = await import('@silvia-odwyer/photon');
    setPhoton(photon);
  } finally {
    console.log("loaded wasm successfully");
    setLoadedWasm(true);
  }
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace(/^data:image\/(png|jpg);base64,/, ""));
  reader.onerror = error => reject(error);
});

const ShareImage = ({ initialValue, updateValue, id }) => {
  const [showModal, setShowModal] = useState(false)
  const [imageSrc, setImageSrc] = useState(initialValue)
  const [photon, setPhoton] = useState()
  const [loadedWasm, setLoadedWasm] = useState(false)

  useEffect(() => {
    loadWasm(setPhoton, setLoadedWasm)
  }, [])

  const { isAuthed } = useAuth()

  const drop = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async e => {
    e.preventDefault();
    e.stopPropagation();
    let files = [...e.dataTransfer.files];
    let imageString = await toBase64(files[0]);

    const image = photon.base64_to_image(imageString)
    const croppedImage = photon.crop(image, 0, 0, 1200, 600)
    const resizedImage = photon.resize(croppedImage, 1200, 600, 1)

    const extension = files[0].type.includes('png') ? 'png' : 'jpg'
    const Authorization = await isAuthed()
    const response = await fetch(`${API_URL}/upload/pre-signed/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization
      },
      body: JSON.stringify({
        payload: {
          name: `share-image-${Date.now()}.${extension}`,
          contentType: files[0].type
        }
      })
    });
    const { signedUrl: { url, fields } } = await response.json()
    const formData = new FormData();
    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });
    const key = fields.key;
    const newImage = resizedImage.get_base64()
    const res = await fetch(newImage);
    const blob = await res.blob();
    const file = new File([blob], fields.key.split("/").pop(), { type: files[0].type });
    setImageSrc(newImage)
    formData.append("file", file);
    await fetch(url, {
      method: 'POST',
      body: formData,
    });
    setImageSrc(`https://images.getdunmag.com/${key}`)
    updateValue({
      target: {
        id: 'shareImg',
        value: {
          src: `https://images.getdunmag.com/${key}`
        }
      }
    })
  };

  return (
    <div className='seo-image-container' onDrop={drop}>
      {showModal && <Modal />}
      <div className='seo-image'
        onClick={(e) => toggleModal(e, showModal, setShowModal)}
        onDrop={e => handleDrop(e)}
        onDragOver={e => handleDragOver(e)}
        onDragEnter={e => handleDragEnter(e)}
        onDragLeave={e => handleDragLeave(e)}
      >
        <label >
          Share Image
        </label>
        <img src={imageSrc || imageErrorSrc}
          onError={(e) => (e.target.onerror = null, e.target.src = imageErrorSrc)}
        />
      </div>
    </div>
  );
}

export default ShareImage;