/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Loading from 'components/loading';
import { Toolbar, Button, makeStyles } from '@material-ui/core';
const { REACT_APP_STAGE: STAGE } = process.env;

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'green',
  },
  logo: {
    fontWeight: 600,
    color: '#FFFEFE',
    textAlign: 'left',
  },
  menuButton: {
    fontWeight: 700,
    size: '18px',
    marginLeft: '38px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const headersData = [
  {
    label: 'New Article',
    href: '/posts/new',
    authed: true,
  },
];

const getMenuButtons = (menuButton) => {
  const buttonProps = {
    color: 'inherit',
    className: menuButton,
  };

  return headersData.map(({ label, href, onClick }) => {
    buttonProps.key = label;
    if (onClick) {
      buttonProps.onClick = props[onClick];
    } else {
      buttonProps.component = RouterLink;
      buttonProps.to = href;
    }
    return <Button {...buttonProps}>{label}</Button>;
  });
};

const API_URL = `https://api.${STAGE || 'dev'}.bevymagazine.com`;
const getArticles = async (setArticles, isAuthed, setLoading) => {
  const Authorization = await isAuthed();
  const response = await fetch(`${API_URL}/articles`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
  });
  const data = await response.json();
  setArticles(data);
  setLoading(false);
};

const articleClick = (e, articleId, navigate) => {
  e.preventDefault();
  const id = articleId.substring(articleId.lastIndexOf('#') + 1);
  navigate(`/posts/edit/${id}`);
};

const Post = () => {
  const { menuButton, toolbar } = useStyles();
  const [articles, setArticles] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { isAuthed } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    getArticles(setArticles, isAuthed, setLoading);
  }, []);

  return (
    <div className="page">
      <h1>Articles</h1>
      <div className="createArticle">
        <Toolbar className={toolbar}>
          <div>{getMenuButtons(menuButton)}</div>
        </Toolbar>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="articleList">
          <table>
            <tbody>
              {articles &&
                articles.map((article) => (
                  <tr
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => articleClick(e, article.sk, navigate)}
                    key={article.sk}
                  >
                    <td>{article.title}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Post;
