/* eslint-disable no-undef */
import { useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Input from 'components/form-inputs'
import Editor from 'components/editor'
import debounce from 'just-debounce-it';
import useAuth from '../../hooks/useAuth'
import { useNavigate } from "react-router-dom";

const { REACT_APP_STAGE: STAGE } = process.env

const API_URL = `https://api.${STAGE || 'dev'}.bevymagazine.com`
const id = uuidv4()

const initialEditorValue = {
  "time": Math.floor(Date.now() / 1000),
  "blocks": [
    {
      "type": "paragraph",
      "data": {
        "text": "This is the beginning of something special",
        "level": 1
      }
    },
  ]
}

const createArticleClick = (e, navigate) => {
  e.preventDefault()
  navigate(`/posts/edit/${id}`)
}

const saveArticle = async (data, isAuthed, setIsCreated) => {
  const Authorization = await isAuthed()
  await fetch(`${API_URL}/article/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({ payload: data })
  });
  setIsCreated(true)
}

const NewPost = () => {
  const [isCreated, setIsCreated] = useState(false)
  const formData = useRef({ body: { ...initialEditorValue } })
  const { isAuthed } = useAuth()
  const navigate = useNavigate()

  const debouncedSavedArticle = useMemo(
    () => debounce(saveArticle, 300)
    , []);

  const updateValue = (event) => {
    const newFormData = { ...formData.current, [event.target.id]: event.target.value }
    formData.current = newFormData
    debouncedSavedArticle(newFormData, isAuthed, setIsCreated)
  }


  return (
    <div>
      <h1>New Article</h1>
      {isCreated && <div>
        <button onClick={(e) => createArticleClick(e, navigate)}>Create Article</button>
      </div>
      }
      <form style={{ width: '100%' }}>
        <Input id='title' type='input' msg='title' updateValue={updateValue} />
        <Editor id='body' postId={id} initialValue={initialEditorValue} updateValue={updateValue} />
      </form>
    </div>
  );
};

export default NewPost