import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'green',
  },
  logo: {
    fontWeight: 600,
    color: '#FFFEFE',
    textAlign: 'left',
  },
  menuButton: {
    fontWeight: 700,
    size: '18px',
    marginLeft: '38px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const headersData = [
  {
    label: 'Home',
    href: '/',
    authed: false,
  },
  {
    label: 'Posts',
    href: '/posts',
    authed: true,
  },
  {
    label: 'login Up',
    href: '/login',
    authed: false,
  },
  {
    label: 'Log Out',
    onClick: 'handleLogout',
    authed: true,
  },
];

export default function Nav(props) {
  const { isAuthed } = props;
  const { header, logo, menuButton, toolbar } = useStyles();

  const dunTitle = () => (
    <Typography variant="h6" component="h1" className={logo}>
      DUN Magazine Admin Site
    </Typography>
  );

  const getMenuButtons = () => {
    const buttonProps = {
      color: 'inherit',
      className: menuButton,
    };

    return headersData
      .filter(({ authed }) => authed === isAuthed)
      .map(({ label, href, onClick }) => {
        buttonProps.key = label;
        if (onClick) {
          buttonProps.onClick = props[onClick];
        } else {
          buttonProps.component = RouterLink;
          buttonProps.to = href;
        }
        return <Button {...buttonProps}>{label}</Button>;
      });
  };
  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {dunTitle()}
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };
  return (
    <header>
      <AppBar className={header}>{displayDesktop()}</AppBar>
    </header>
  );
}
