import './input.scss'

const Input = ({ type, id, msg, rows = 4, readonly, autofocus, required, value, updateValue, defaultValue, labelClass ='floating-label', errorMessage}) => {
  return (
    <div className="floating-label-group">
      {type === 'textarea' ?
        <>
          <textarea
            type={type}
            id={id}
            rows={rows}
            className={`${type} form-control ${readonly ? 'readonly' : ''}`}
            autoFocus={autofocus}
            required={required}
            value={value}
            defaultValue={defaultValue}
            onChange={updateValue}
            placeholder=" "
            readOnly={readonly}
          />
          <label className={labelClass}>
            {msg}
            {errorMessage && <span className="error-message">{errorMessage}</span>}
          </label>
        </>
        
        :
        <>
          <input
            type={type}
            id={id}
            className='form-control'
            autoFocus={autofocus}
            required={required}
            defaultValue={defaultValue}
            value={value}
            onChange={updateValue}
            placeholder=" "
            readOnly={readonly}
          />
          <label className={labelClass}>
            { msg }
            {errorMessage && <span className="error-message">{errorMessage}</span>}
          </label>
        </>
      }
    </div>
  )
}

export default Input