/* eslint-disable no-undef */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const poolData = {
  UserPoolId: 'us-west-2_0m5KnHTnn',
  ClientId: '4e43vms9010qr7si0spqhk46i6',
};

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

function createAttributeList(formData) {
  return Object.keys(formData).map((key) => (new AmazonCognitoIdentity.CognitoUserAttribute({ Name: key, Value: formData[key] })))
}

function createAuthenticationDetails(formData) {
  const authenticationData = {
    Username: formData.email,
  };
  return new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
  );
}

function createCognitoUser(formData) {
  const userData = {
    Username: formData.email,
    Pool: userPool,
  };
  return new AmazonCognitoIdentity.CognitoUser(userData);
}

const AuthContext = createContext({});

export function AuthProvider({
  children,
}){
  const [user, setUser] = useState();
  const [authed, setAuthed] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);
 
  const navigate = useNavigate();
  const location = useLocation();

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  useEffect(() => {
    isAuthed()
      .then(() => setAuthed(true))
      .catch((_error) => { console.log('error', _error);  setAuthed(false) })
      .finally(() => setLoadingInitial(false));
  }, []);

  function isAuthed() {
    if (!userPool) {
      return Promise.reject();
    }
    return new Promise((res, rej) => {
      const user = userPool.getCurrentUser();
      if (!user) {
        console.log('Failed to get user from user pool');
        localStorage.removeItem('isAuthed');
        setAuthed(false)
        res(false);
        return;
      }
      user.getSession(
        async (err, session) => {
          if (err) {
            console.log('Failed to get the user session', err);
            rej(err);
            return;
          }
          const { jwtToken: idToken } = session.getIdToken()
          // localStorage.setItem('isAuthed', idToken);
          res(idToken)
        });
    });
  }

  function login(formData) {
    return new Promise((resolve, reject) => {
      setLoading(true);
      const authenticationDetails = createAuthenticationDetails(formData)
      const cognitoUser = createCognitoUser(formData)
      cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
      cognitoUser.initiateAuth(authenticationDetails, {
        onSuccess: async () => {
          const currentUser = await userPool.getCurrentUser();
          setLoading(false);
          resolve(currentUser);
        },
        onFailure: err => {
          console.log('err', err)
          setLoading(false);
          reject(err);
        },
        customChallenge: challengeParam => {
          cognitoUser['challengeName'] = 'CUSTOM_CHALLENGE';
          cognitoUser['challengeParam'] = challengeParam;
          setLoading(false);
          resolve(cognitoUser);
        },
      });
    });
    
  }

  function verify(formData) {
    return new Promise((resolve, reject) => {
      setLoading(true)
      const { cognitoUser, answer } = formData
      cognitoUser.sendCustomChallengeAnswer(answer, {
        onSuccess: async () => {
          const currentUser = await userPool.getCurrentUser();
          currentUser.getSession(async (err, session) => {
            if (err) {
              console.log('Failed to get the user session', err);
              rej(err);
              return;
            }
            const { jwtToken: idToken } = session.getIdToken()
            localStorage.setItem('isAuthed', idToken);
            setAuthed(true)
            setLoading(false)
            resolve(currentUser);
          });
        },
        onFailure: err => {
          console.log('err', err)
          setLoading(false)
          reject(err);
        },

      });
    });
  }

  // Sends sign up details to the server. On success we just apply
  // the created user to the state.
  function signUp(formData) {
    return new Promise((res) => {
      const attributeList = createAttributeList(formData)
      userPool.signUp(formData.email, 'password', attributeList, null, function (
        err,
        result
      ) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        var cognitoUser = result.user;
        res(cognitoUser)
      });

    });
  }

  // Call the logout endpoint and then remove the user
  // from the state.
  function logout() {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      if (!user) {
        console.log('Failed to get user from user pool');
        localStorage.removeItem('isAuthed');
        setAuthed(false)
        resolve('No current user');
        return;
      }
      user.signOut(() => {
        localStorage.removeItem('isAuthed');
        setAuthed(false)
        resolve('User singed out')
      });
    });
  }

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      authed,
      login,
      signUp,
      logout,
      verify,
      isAuthed,
    }),
    [authed, user, loading, error]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}