/* eslint-disable no-undef */
import { useRef, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Input from 'components/form-inputs';
import Loading from 'components/loading';

const Login = () => {
  const [showVerify, setShowVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formData = useRef();
  const navigate = useNavigate();
  const { login, verify, authed } = useAuth();

  const updateValue = (e) => {
    const newFormData = { ...formData.current, [e.target.id]: e.target.value };
    formData.current = newFormData;
  };

  const handleLogin = () => {
    setIsLoading(true);
    login(formData.current).then((cognitoUser) => {
      formData.current = { ...formData.current, cognitoUser };
      setShowVerify(true);
      setIsLoading(false);
    });
  };

  const handleVerify = () => {
    setIsLoading(true);
    verify(formData.current).then(() => {
      setIsLoading(false);
      navigate('/posts');
    });
  };

  if (authed) {
    return <Navigate to="/" />;
  }

  return (
    <div className="page">
      {isLoading ? (
        <Loading />
      ) : showVerify ? (
        <div className="verify">
          <h1>verify</h1>
          <Input
            id="answer"
            type="input"
            msg="verification code"
            updateValue={updateValue}
          />
          <button onClick={handleVerify}>Verify</button>
        </div>
      ) : (
        <div className="login">
          <h1>Login</h1>
          <Input
            id="email"
            type="input"
            msg="email"
            updateValue={updateValue}
          />
          <button onClick={handleLogin}>Log in</button>
        </div>
      )}
    </div>
  );
};

export default Login;
