import { Fragment, useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header'; 
import ImageTool from '@editorjs/bevy-image'

// eslint-disable-next-line no-undef
const { REACT_APP_STAGE: STAGE } = process.env
const EDITOR_HOLDER_ID = 'editorjs';
 
const Editor = ({ initialValue, updateValue, postId }) => {
  const ejInstance = useRef();
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      ejInstance.current.destroy();
      ejInstance.current = null;
    }
  }, []);
 
  const saveData = async () => {
    const content = await ejInstance.current.save()
    updateValue({ target: { id: 'body', value: content } })
  }

  const initEditor = () => {
    const editor = new EditorJS({
      holder: EDITOR_HOLDER_ID,
      logLevel: "ERROR",
      data: initialValue,
      onReady: () => {
        ejInstance.current = editor;
      },
      onChange: () => {
        saveData()
      },
      autofocus: true,
      tools: { 
        header: Header, 
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: `https://api.${STAGE}.bevymagazine.com/upload/image/${postId}`, 
            }
          }
        }
      }, 
    });
  };
  
  return (
    <Fragment>
      <div id={EDITOR_HOLDER_ID}> </div>
    </Fragment>
  );
}
 
export default Editor;