/* eslint-disable no-undef */
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import Input from 'components/form-inputs'

const SignUp = () => {
  const formData = useRef()
  const navigate = useNavigate();
  const [showVerify, setShowVerify ] = useState(false)
  const { signUp, loading, login, verify } = useAuth();

  const updateValue = (e) => {
    const newFormData = { ...formData.current, [e.target.id]: e.target.value }
    formData.current = newFormData
  };

  const handleLogin = () => {
    signUp(formData.current).then(() => {
      login(formData.current).then((cognitoUser) => {
        formData.current = { ...formData.current, cognitoUser }
        setShowVerify(true)
      });
    });
  };

  const handleVerify = () => {
    verify(formData.current).then(() => {
      navigate('/posts')
    });
  }

  return (
    <div>
      {loading ? <div>is loading</div> :
        showVerify ?
          <div className='verify'>
            <h1>verify</h1>
            <Input id='answer' type='input' msg='verification code' updateValue={updateValue} />
            <button onClick={handleVerify}>
              Verify
            </button>
          </div >
          :
          <div className='login'>
            <h1>Sign Up</h1>
            <Input id='name' type='input' msg='name' updateValue={updateValue} />
            <Input id='email' type='input' msg='email' updateValue={updateValue} />
            <button onClick={handleLogin}>
              Sign Up
            </button>
          </div>
      }
      
    </div>
  );
};

export default SignUp