/* eslint-disable no-undef */
import { useMemo, useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Input from 'components/form-inputs';
import Editor from 'components/editor';
import debounce from 'just-debounce-it';
import useAuth from '../../hooks/useAuth';
import Select from 'react-select/creatable';
import ShareImage from 'components/share-image';
import Loading from 'components/loading';
import { Toolbar, Button, makeStyles } from '@material-ui/core';

const slugify = require('slugify');
const { REACT_APP_STAGE: STAGE } = process.env;
const API_URL = `https://api.${STAGE || 'dev'}.bevymagazine.com`;

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'green',
  },
  logo: {
    fontWeight: 600,
    color: '#FFFEFE',
    textAlign: 'left',
  },
  menuButton: {
    fontWeight: 700,
    size: '18px',
    marginLeft: '38px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const saveArticle = async (data, isAuthed) => {
  const id = data.typeId.split('#').slice(-1)[0];
  const Authorization = await isAuthed();
  await fetch(`${API_URL}/article/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify({ payload: data }),
  });
};

const saveItem = async (isAuthed, payload) => {
  const Authorization = await isAuthed();
  await fetch(`${API_URL}/save/item/new`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify({ payload }),
  });
};

const publishArticle = async (e, isAuthed, payload, setIsLoading) => {
  e.preventDefault();
  const Authorization = await isAuthed();
  const id = payload.typeId.split('#').slice(-1)[0];
  payload.published = Math.floor(Date.now() / 1000);
  payload.slug = slugify(payload.title, { lower: true });
  console.log('id', id);
  console.log('payload', payload);
  setIsLoading(true);
  await fetch(`${API_URL}/article/${id}/publish`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify({ payload }),
  });
  setIsLoading(false);
};

const getArticle = async (
  id,
  setIsLoading,
  formData,
  setSelectedTag,
  setSelectedCategory,
  setSelectedByline,
  isAuthed
) => {
  const Authorization = await isAuthed();
  const response = await fetch(`${API_URL}/get/post/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
  });
  formData.current = await response.json();
  setSelectedTag(formData.current.tags);
  setSelectedCategory(formData.current.categories);
  setSelectedByline(formData.current.byline);
  setIsLoading(false);
};

const getItems = async (isAuthed, setFunction, item) => {
  const Authorization = await isAuthed();
  const response = await fetch(`${API_URL}/list/${item}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
  });
  const items = await response.json();
  if (!items.error) {
    setFunction(
      (items || []).map((item) => ({ value: item.pk, label: item.title }))
    );
  }
};

const buttonList = [
  {
    label: 'Publish',
    onClick: publishArticle,
  },
];

const EditArticle = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [bylines, setBylines] = useState([]);
  const [selectedByline, setSelectedByline] = useState();
  const formData = useRef();
  const { isAuthed } = useAuth();
  const location = useLocation();
  const { menuButton, toolbar } = useStyles();
  const state = {
    categories: setSelectedCategory,
    tags: setSelectedTag,
    byline: setSelectedByline,
  };

  const id = /[^/]*$/.exec(location.pathname)[0];

  useEffect(() => {
    getArticle(
      id,
      setIsLoading,
      formData,
      setSelectedTag,
      setSelectedCategory,
      setSelectedByline,
      isAuthed
    );
    getItems(isAuthed, setTags, 'tags');
    getItems(isAuthed, setCategories, 'categories');
    getItems(isAuthed, setBylines, 'byline');
  }, []);

  const debouncedSavedArticle = useMemo(() => debounce(saveArticle, 300), []);

  const onSelect = (newValue, { name }) => {
    const newFormData = { ...formData.current, [name]: newValue };
    formData.current = newFormData;
    state[name](newValue);
    debouncedSavedArticle(newFormData, isAuthed);
  };

  const updateValue = (event) => {
    const newFormData = {
      ...formData.current,
      [event.target.id]: event.target.value,
    };
    formData.current = newFormData;
    debouncedSavedArticle(newFormData, isAuthed);
  };

  const handleCreate = (type, title) => {
    const slug = slugify(title, { lower: true });
    const key = `${type.toUpperCase()}#${slug}`;
    const payload = {
      key,
      slug,
      title,
      type,
    };
    saveItem(isAuthed, payload);
    const newOption = { value: payload.key, label: payload.title };
    onSelect(newOption, { name: payload.type, action: 'select-option' });
  };

  const getMenuButtons = (menuButton) => {
    const buttonProps = {
      color: 'inherit',
      className: menuButton,
    };

    return buttonList.map(({ label, href, onClick }) => {
      buttonProps.key = label;
      if (onClick) {
        buttonProps.onClick = onClick;
      } else {
        buttonProps.component = RouterLink;
        buttonProps.to = href;
      }
      return <Button {...buttonProps}>{label}</Button>;
    });
  };

  return (
    <div className="page">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h1>Edit Article</h1>
          <div className="articleActions">
            <Toolbar className={toolbar}>
              <div>{getMenuButtons(menuButton)}</div>
            </Toolbar>
          </div>
          <div className="articleDetailsContainer">
            <div className="articleDetails">
              <div className="title">
                <Input
                  id="title"
                  type="input"
                  msg="title"
                  defaultValue={formData.current.title}
                  updateValue={updateValue}
                />
              </div>
              <div className="headline">
                <Input
                  id="headline"
                  msg="headline"
                  type="textarea"
                  defaultValue={formData.current.headline}
                  updateValue={updateValue}
                />
              </div>
              <div className="articleImages">
                <ShareImage
                  id={id}
                  initialValue={formData.current.shareImg?.src || ''}
                  updateValue={updateValue}
                />
              </div>
            </div>
            <div className="relationships">
              <div className="byline">
                <label>Byline</label>
                <Select
                  id="byline"
                  name="byline"
                  isClearable
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onChange={(newValue, actionMeta) =>
                    onSelect(newValue, actionMeta)
                  }
                  onCreateOption={(inputValue) =>
                    handleCreate('byline', inputValue)
                  }
                  options={bylines}
                  value={selectedByline}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
              <div className="category">
                <label>Category</label>
                <Select
                  id="categories"
                  name="categories"
                  isClearable
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onChange={(newValue, actionMeta) =>
                    onSelect(newValue, actionMeta)
                  }
                  onCreateOption={(inputValue) =>
                    handleCreate('categories', inputValue)
                  }
                  options={categories}
                  value={selectedCategory}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
              <div className="tags">
                <label>Tags</label>
                <Select
                  id="tags"
                  name="tags"
                  isClearable
                  isMulti
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onChange={(newValue, actionMeta) =>
                    onSelect(newValue, actionMeta)
                  }
                  onCreateOption={(inputValue) =>
                    handleCreate('tags', inputValue)
                  }
                  options={tags}
                  value={selectedTag}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            </div>
          </div>
          <div className="articleContainer">
            <form style={{ width: '100%' }}>
              <Editor
                id="body"
                postId={id}
                initialValue={formData.current.body}
                updateValue={updateValue}
              />
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default EditArticle;
