import 'styles/App.scss';
import { Routes, Route, Outlet, Navigate} from "react-router-dom";
import Header from 'components/header';
import Login from './pages/login'
import SignUp from './pages/signUp';
import Post from './pages/posts'
import NewPost from './pages/posts/new'
import EditPost from './pages/posts/edit'
import useAuth, { AuthProvider } from './hooks/useAuth'

const Home = () => {
  const { authed } = useAuth()
  return (
    <>
      <h1>Home (Public)</h1>
    </>)
}

function PrivateOutlet({ isAuthed }) {
  return isAuthed ? <Outlet /> : <Navigate to="/login" />;
}

const Layout = () => {
  const { authed, logout } = useAuth()

  const handleLogout = () => {
    logout().then(() => {
      return <Navigate to="/login" push />
    })
  }
  return (
    <>
      <Header isAuthed={authed} handleLogout={handleLogout} />
      <div className='body'>
        <Routes>
          <Route path="/posts" element={<PrivateOutlet isAuthed={authed} />}>
            <Route path='/posts' element={<Post />} />
            <Route path='/posts/new' element={<NewPost />} />
            <Route path='/posts/edit/:id' element={<EditPost />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
      
    </>
  )
}

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Layout />
      </AuthProvider>
    </div>
  );
}

export default App;
